import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainInterceptor } from './interceptor/main.interceptor';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NoconectivityComponent } from './noconectivity/noconectivity.component';
import { DesktopMessageComponent } from './desktop-message/desktop-message.component';
import { QrComponent } from './qr/qr.component';
import { ButonSoundComponent } from './modules/shared/buton-sound/buton-sound.component';

@NgModule({ declarations: [
        AppComponent,
        NoconectivityComponent,
        DesktopMessageComponent,
        QrComponent,
    ],
    exports: [],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SweetAlert2Module.forRoot()], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: MainInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
