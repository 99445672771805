import { Component } from '@angular/core';
import { SecurityService } from './modules/vinculaciones/services/security.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app-2023';
  hasInternet = true;
  isDesktopDevice = false;

  constructor(private securityService: SecurityService,
              private deviceService: DeviceDetectorService,
    ) {
    this.securityService.hasInternetSubject.subscribe((hasInternet: boolean) => {
      this.hasInternet = hasInternet;
      this.isDesktopDevice = this.deviceService.isDesktop();
    }
    );
  }

  
}
