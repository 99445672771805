<div class="fixed inset-0 bg-blue-800 bg-opacity-100" id="overlay">
  
</div>
<div class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-max">
  <div id="bg-white" class=" w-[600px] h-[250px] bg-white mt-[-310px]"></div>
</div>

<div class="top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute w-screen">
  <div class="text-center">
    <span class="text-yellow-400 font-bold text-4xl">
      FELICITACIONES
    </span>
    <br>
    <br>
    <img id="prize" class="w-full max-w-[500px] mt-[-3em] mb-[-5em]" src="../../../../assets/trofeo.png" alt="Descripción de la imagen">
    <br>
    <div class="text-white font-bold text-2xl leading-tight">
      <div class="text-center overflow-hidden">
        <span class="relative inline-block px-2.5">
          ¡CONTINÚA <br> AVANZANDO!
          <span class="absolute top-1/2 right-full border-b-2 border-white mr-3"></span>
          <span class="absolute top-1/2 left-full border-b-2 border-white ml-3"></span>
        </span>
      </div>
    </div>
    <button class="btn btn-warning" (click)="finish()">Aceptar</button>
    <app-buton-sound class="flex justify-center pt-4 w-full"></app-buton-sound>
  </div>
</div>