<div class="">
    <button
      class="w-10 h-10 bg-aliceblue flex  justify-center items-center rounded-full transition-colors duration-300 focus:outline-none  "
      [ngClass]="{'bg-lightgray': soundEnabled$ | async}"
      (click)="toggleSound()">
      <img
        [src]="(soundEnabled$ | async) ? '../../../assets/svg/volume.svg' : '../../../assets/svg/volume-no.svg'"
        alt="Sound Icon"
        class="w-20 h-20"
      >
    </button>
  </div>