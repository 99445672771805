<div class="px-3 h-[10vh] flex flex-row justify-between items-center body-blue text-white">
  <div (click)="back()">Regresar</div>
  <div class="h-[10vh] flex flex-row justify-end items-center p-0 float-right">
    <img src="assets/svg/logo-blanco.svg" class="h-[55px] float-right" alt="Logo Fincomercio" />
  </div>
</div>
<div class="pt-16 h-[90vh] flex flex-col items-center justify-start text-center bg-gray-200">
  <div class="container">
    <div class="row">
      <div
        *ngIf="!isOtpValidated"
        class="col d-flex justify-content-center mb-4 text-[#003aa2] font-extrabold text-[2em]"
      >
        Firma tu actualización
      </div>
      <div
        *ngIf="isOtpValidated"
        class="col d-flex justify-content-center mb-4 text-[#003aa2] font-extrabold text-2xl"
      >
        La actualización ya ha sido firmada
      </div>
    </div>
    <div *ngIf="!isOtpValidated" class="row">
      <div class="col text-center">
        <form [formGroup]="form">
          <div class="mb-3">
            <label for="address" class="form-label text-[#555555]"
              >Hemos enviado a tu número celular registrado un código de seis
              (6) digitos, ingresalo aquí para firmar tu actualización:</label
            >
            <input
              formControlName="code"
              type="text"
              class="form-control"
              id="address"
              aria-describedby="addressHelp"
            />
            <!-- <div id="emailHelp" class="form-text">Es la mejor forma de identificarte.</div> -->
          </div>
        </form>
        <div>Podrá reenviar el código en {{ seconds }} segundos...</div>
        <input
          type="button"
          class="btn btn-primary btn-custom"
          value="Reenviar código"
          (click)="resendCode()"
          [disabled]="seconds > 0"
        />
      </div>
    </div>

    <div>
      <button
        [disabled]="form.invalid && !isOtpValidated"
        type="button"
        class="btn btn-primary btn-custom"
        (click)="checkCode()"
        style="margin-top: 2em"
      >
        Continuar
      </button>
    </div>
  </div>
</div>
<app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow>
