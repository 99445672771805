<hr>
<div class="text-qr text-base leading-6 font-thin mb-2 text-center">
    <p class="mb-2">Si aún no estás desde tu teléfono, por favor escanea el QR con la cámara de tu celular.</p>
    <img [src]="consultor?.qrImage" alt="Código consultor" class="my-4">
    <div class="space-y-2">
        <span (click)="share()">Asesor: {{ consultor?.name | titlecase }}</span>
        <span (click)="share()">Actualización Digital Gamificada</span>
        <span (click)="share()">{{ getFullUrl() }}</span>
        <img (click)="share()" class="share w-8 h-8" src="assets/share.png">
    </div>
</div>
