import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, catchError, map, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class LogRedebanSoyyoService {

  constructor(private httpClient: HttpClient,
              private router: Router,) { }

  setLogRedebanSoyyo(typeTx: string, detail: string, log?: any) {
    const logRedebanSoyyo = {
      typeTx: typeTx,
      detail: detail,
      log: log,
    }
    return this.httpClient.post(`${ environment.serverUrl }/app/logRedebanSoyyo/addLog`, logRedebanSoyyo)
  }
}