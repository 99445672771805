<div class="relative" (click)="openModal()">
  <div 
    (touchstart)="onTouchStart($event)"
    (document:touchend)="onTouchEnd($event)"
    (document:touchmove)="onTouchMove($event)"
    class="fixed z-100 p-2 w-auto h-auto rounded-full flex justify-center items-center bg-[#25D366] shadow-lg cursor-pointer"
    [ngStyle]="{'top.px': position.top, 'left.px': position.left}"
    style="transition: top 0.3s ease, left 0.3s ease"
  >
    <div>
      <img src="assets/svg/whatsapp.svg" alt="WhatsApp" class="w-10 h-10">
    </div>

    <!-- Mensaje "Ayuda" -->
    <div *ngIf="isHelpMessageVisible" class="absolute -bottom-12 mb-3 bg-gray-800 text-white text-xs px-2 py-1 rounded shadow-lg">
      Ayuda
    </div>
  </div>
</div>

  
  <div 
  *ngIf="isModalVisible" 
  class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-100"
>
  <div class="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
    <h2 class="text-xl font-semibold mb-4">¿Seguro que quieres continuar con la asistencia?</h2>
    <p class="text-gray-600 mb-6">Serás dirigido a una línea de WhatsApp para solicitar ayuda con tu consulta.</p>
    
    <div class="flex flex-col mt-4">
      <button 
        class=" btn-custom  text-white py-2 px-4 rounded hover:bg-green-500 transition duration-300 ease-in-out"
        (click)="continueToWhatsApp()"
      >
        Continuar
      </button>
      <button 
        class="button-back mt-2 text-gray-700 py-2 px-4 rounded hover:bg-gray-400 transition duration-300 ease-in-out"
        (click)="closeModal()"
      >
        Cancelar
      </button>
    </div>
  </div>
</div>