import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LocalstorageService } from '../services/localstorage.service';
import { SecurityService } from '../services/security.service';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.scss']
})
export class FinishComponent implements OnInit {

  backRoute = '/app/balance';
  phone;
  serverUrl = environment.serverUrl;

  constructor(private router: Router,
              private title: Title,
              private localStorageService: LocalstorageService,
              private security: SecurityService
    ) {
      this.title.setTitle('Actualización Digital | Finalizar');
      this.phone = this.localStorageService.get('phone');
    }

  async ngOnInit() {
    const route = localStorage.getItem('route');
    const currentUrl = this.router.url;
    if(route && currentUrl !== route) {
      await this.router.navigateByUrl(route);
    }
    if(!route) {
      await this.router.navigateByUrl('/');
    }
  }

  downloadPDF() {
    this.security.getPDF().subscribe({
      next: (response: any) => {
      
        const file = `${this.serverUrl}/${response.pdfFile}`;
        const url = file;
        const a = document.createElement('a');
        a.href = url;
        a.target = "_blank";
        a.download = 'vinculacionDigitalFincomercio.pdf'; // Nombre del archivo descargado
        a.click();
      },
      error: (error: any) => {
        // localStorage.clear();
        // window.location.href = 'https://www.fincomercio.com';    
      }
  });
  }
    
  finish() {
    localStorage.clear();
    window.location.href = 'https://www.fincomercio.com';
  }

  async back() {
    // localStorage.setItem('route', this.backRoute);
    // this.router.navigateByUrl(this.backRoute);
  }

  

}
