import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SoundService {
  private soundSubject: BehaviorSubject<boolean>;

  constructor() {
    const initialValue = localStorage.getItem('SoundValue') === 'true';
    this.soundSubject = new BehaviorSubject<boolean>(initialValue);
    this.sound$ = this.soundSubject.asObservable();
  }

  sound$: Observable<boolean>;

  toggleSound() {
    const newValue = !this.soundSubject.value;
    this.soundSubject.next(newValue);
    localStorage.setItem('SoundValue', newValue.toString());
  }
}