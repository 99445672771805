import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-field-number',
  templateUrl: './field-number.component.html',
  styleUrls: ['./field-number.component.scss']
})
export class FieldNumberComponent implements AfterViewInit, OnChanges {

  @ViewChild('field') field!: ElementRef;
  @Input() fieldLabel = '';
  @Input() minValidValue = 0;
  @Input() errorValueMessage = 'El valor ingresado no es válido';
  @Input() defaultValue: any = null;
  @Output() isValidEmiter = new EventEmitter<boolean>();
  @Output() fieldValue = new EventEmitter<number | null>();
  isValid = false;
  timer: any;
  isTimeout = false;

  constructor() {
    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, 5000);
  }

  ngAfterViewInit() {
    if(this.defaultValue) {
      this.field.nativeElement.value = (+this.defaultValue).toLocaleString('en-US');;
      this.isValid = true;
      this.isValidEmiter.emit(this.isValid);
    }
    this.field.nativeElement.addEventListener('input', this.formatInput.bind(this));
  }

  ngOnChanges() {
    if(this.defaultValue) {
      this.field.nativeElement.value = (+this.defaultValue).toLocaleString('en-US');;
      this.isValid = true;
      this.isValidEmiter.emit(this.isValid);
    }
  }

  formatInput(event) {

    let cursorPosition = event.target.selectionStart;
    let lengthBeforeFormat = event.target.value.length;

    // Removemos los caracteres no numéricos
    event.target.value = event.target.value.replace(/[\D\s\._\-]+/g, "");
    
    // Guardamos el valor numérico antes de la formateación
    let numericValue = Number(event.target.value);

    // Formateamos el valor
    event.target.value = (+event.target.value).toLocaleString('en-US');
    
    // Calculamos la diferencia de longitud debido a la formateación
    let lengthAfterFormat = event.target.value.length;
    let formatLengthDifference = lengthAfterFormat - lengthBeforeFormat;

    // Si el valor numérico termina en "0" y no estamos al final del input,
    // ajustamos la diferencia de longitud para que el cursor se coloque correctamente
    // if (numericValue.endsWith("0") && cursorPosition !== numericValue.length) {
    //     formatLengthDifference -= 1;
    // }

    // Ajustamos la posición del cursor
    event.target.selectionStart = cursorPosition + formatLengthDifference;
    event.target.selectionEnd = cursorPosition + formatLengthDifference;
    this.isValid = numericValue >= this.minValidValue;
    if(!this.isValid) {
      this.errorValueMessage = `El valor debe ser superior a ${this.minValidValue.toLocaleString('en-US') }`
    }
    this.isValidEmiter.emit(this.isValid);
    // console.log(typeof numericValue, numericValue);
    if(typeof numericValue === 'number') {
      if(this.isValid) {
        this.fieldValue.emit(numericValue);
      } else {
        this.fieldValue.emit(null);
      }
    } else {
      this.fieldValue.emit(null);
    }
  }


}
