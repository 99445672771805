<ng-container *ngIf="consultor.user">
    <div class="fixed top-0 left-0 w-full h-full body-blue"></div>

    <div class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center flex-col text-white text-center text-xl z-[9999]">
        <h1>ACTUALIZA TUS DATOS</h1>
        <img src="assets/svg/logo-blanco.svg" class="w-24 h-24 my-4" alt="Logo Fincomercio">
        <br>
        Para mejorar tu experiencia, por favor ingresa desde tu celular.
        <br class="my-2">
        Escanea el siguiente código QR con tu celular.
        <br>
        <app-qr class="my-2 flex flex-col justify-center items-center"></app-qr>
    </div>
</ng-container>
