import { Component } from '@angular/core';
import { SecurityService } from '../modules/vinculaciones/services/security.service';
import { LocalstorageService } from '../modules/vinculaciones/services/localstorage.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-desktop-message',
  templateUrl: './desktop-message.component.html',
  styleUrls: ['./desktop-message.component.scss'],
})
export class DesktopMessageComponent {
  qrImage;
  consultor;

  constructor(private title: Title,
              securityService: SecurityService,) {
    this.title.setTitle('Onboarding Digital | Inicio');
    securityService.consultor
      .subscribe((consultor: any) => {
        this.consultor = consultor;
      });
  }

}
