import { Component, OnInit } from '@angular/core';
import anime from 'animejs';

@Component({
  selector: 'app-waiting',
  templateUrl: './waiting.component.html',
  styleUrls: ['./waiting.component.scss']
})
export class WaitingComponent implements OnInit {

  ngOnInit() {
    setTimeout( () => {
      this.animateCircle();
    }, 100);
  }
  
  animateCircle() {
    const containerWidth = document.getElementById('loading-container')!.offsetWidth;
    anime({
      targets: '.loading-circle',
      translateX: [
        { value: `${containerWidth - 30}px`, easing: 'easeOutQuad' },
        { value: '0px', easing: 'easeInQuad' }
      ],
      duration: 2000,
      loop: true
    });
  }

}
