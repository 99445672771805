<header class="container" style="border: blue solid 1px">
  <div class="row">
    <div class="col-6" style="border: green solid 1px">
      <img src="/assets/fincomercio.png" style="height: 50px" />
    </div>
    <div class="col-6 text-end" style="border: purple solid 1px">
      <span>250</span>
      <img src="/assets/coins.png" style="height: 50px" />
    </div>
  </div>
  <div class="row">
    <div class="col">
      Su asesor asignado es Fredy Ruiz
      <img src="/assets/whatsapp.png" style="height: 20px" />
      <img class="float-end" src="/assets/whatsapp.png" style="height: 20px; margin-top: 2px;" />
    </div>
  </div>
</header>
