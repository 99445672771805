<form [formGroup]="form">
    <div class="mb-3">
        <label for="location" class="form-label">{{ label }}</label>
        <input type="text" class="form-control" #location id="location" formControlName="location" aria-describedby="locationHelp" 
          placeholder="Ej: Bogotá"
          [ngClass]="{'is-invalid': !isValid && (isTimeout || form.get('location')?.touched)}" (blur)="validateBlur()" 
           (keydown)="makeInvalid()"    (input)="filterLocations($event)"  (keydown.escape)="esc()">

        <ul id="list" class="list-group mt-2" *ngIf="filteredLocations && filteredLocations.length > 0">
          <li class="list-group-item" *ngFor="let location of filteredLocations" (click)="selectLocation(location.id,  location.name)">{{ location.name }}</li>
        </ul>
        <div *ngIf="!isValid && (isTimeout || form.get('location')?.touched)" class="form-text">Por favor seleccione un elemento de la lista</div>
    </div>
</form>
  