import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LocalstorageService } from '../services/localstorage.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  @Input() error = '';
  @Input() reload = false;
  @Output() signalRetry = new EventEmitter<boolean>();
  url: SafeUrl = '';
  phone;

  constructor(private sanitizer: DomSanitizer,
              private localStorageService: LocalstorageService
    ) {
      this.phone = this.localStorageService.get('phone');
  }

  ngOnInit(): void {

    const greating = `Hola, necesito tu ayuda para continuar con mi vinculación digital.`;
    const errorMessage = `Me sale el error: ${ this.error }. Gracias`;
    const consolidateErrorMessage = encodeURIComponent(`${greating}${errorMessage}`);
    this.url = this.sanitizer.bypassSecurityTrustUrl(`https://api.whatsapp.com/send?phone=+57${this.phone}&text=${consolidateErrorMessage}`);
  }

  retry() {
    if(this.reload) {
      location.reload();
      location.reload();
      location.reload();
    } else {
      this.signalRetry.emit(true);
    }
  }

}
