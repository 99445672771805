import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('password') password!: ElementRef;
  @ViewChild('togglePasswordButton') togglePasswordButton!: ElementRef;
  form: FormGroup;

  constructor() {
    this.form = new FormGroup({
      
    })
   }

  ngOnInit(): void {}

  togglePassword(): void {
    const passwordField = this.password.nativeElement;

    const type = passwordField.getAttribute('type') === 'password' ? 'text' : 'password';
    passwordField.setAttribute('type', type);

    const icon = this.togglePasswordButton.nativeElement.querySelector('.bi');
    icon.classList.toggle('bi-eye');
    icon.classList.toggle('bi-eye-slash');
  }

}
