import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoyYoService } from './services/soy-yo.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [],
  providers: [
    SoyYoService,
  ],
})
export class SoyYoModule { }
