import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SecurityModule } from './modules/security/security.module';
import { VinculacionesModule } from './modules/vinculaciones/vinculaciones.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/vinculaciones/vinculaciones.module').then(m => m.VinculacionesModule),
  },
  // {
  //   path: 'security',
  //   loadChildren: () => import('./modules/security/security.module').then(m => m.SecurityModule),
  // },
  // {
  //   path: '',
  //   loadChildren: () => import('./modules/shared/shared.module').then(m => m.SharedModule),
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    VinculacionesModule,
    SecurityModule,
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
